<template>
  <div class="pb-5">
    <p>Select an existing link account or add a new one</p>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="link-account"
              class="form-control"
              v-model="
                widgetDetails.integrationWidgetSettings.linkAccountDetails
              "
              @input="
                widgetDetails.integrationWidgetSettings.linkAccountDetails =
                  $event
              "
              :class="{
                'is-value-exist':
                  widgetDetails.integrationWidgetSettings.linkAccountDetails !=
                  null,
                'is-invalid':
                  !widgetDetails.integrationWidgetSettings.linkAccountDetails &&
                  formSubmitted,
              }"
            >
              <b-form-select-option
                :value="{
                  id: account.id,
                  authenticationType: account.authenticationType,
                }"
                v-for="(account, index) in getFilterdLinkAccounts"
                :key="index"
                >{{ account.accountName }}</b-form-select-option
              >
            </b-form-select>
            <label for="link-account">Select link account</label>
            <b-form-invalid-feedback
              class="d-block"
              v-if="
                !widgetDetails.integrationWidgetSettings.linkAccountDetails &&
                formSubmitted
              "
              >Link account required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group></b-col
      >
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-dropdown class="action-dropdown" variant="primary" no-caret left>
            <template #button-content> Add new link account </template>
            <b-dropdown-item
              aria-controls="add-internal-link-account"
              :aria-expanded="showInternalLinkAccountSideBar"
              href="javascript:void(0)"
              @click="showInternalLinkAccountSideBar = true"
              >Link account</b-dropdown-item
            >
            <b-dropdown-item
              aria-controls="add-external-link-account"
              :aria-expanded="showExternalLinkAccountSideBar"
              href="javascript:void(0)"
              @click="showExternalLinkAccountSideBar = true"
              >External link account</b-dropdown-item
            >
          </b-dropdown>
        </b-form-group>
      </b-col>
    </b-form-row>
    <AddEditInternalLinkAccountSideBar
      :showInternalLinkAccountSideBar="showInternalLinkAccountSideBar"
      @closeSideBar="showInternalLinkAccountSideBar = false"
    />
    <AddEditExternalLinkAccountSideBar
      :showExternalLinkAccountSideBar="showExternalLinkAccountSideBar"
      @closeSideBar="showExternalLinkAccountSideBar = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'LinkAccounts',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted'],
  data () {
    return {
      showInternalLinkAccountSideBar: false,
      showExternalLinkAccountSideBar: false
    }
  },
  components: {
    AddEditInternalLinkAccountSideBar: () =>
      import('../../settings/AddEditInternalLinkAccountSideBar.vue'),
    AddEditExternalLinkAccountSideBar: () =>
      import('../../settings/AddEditExternalLinkAccountSideBar.vue')
  },
  computed: {
    getFilterdLinkAccounts () {
      return this.widgetDetails.integrationWidgetSettings.isPropertyDependent
        ? this.linkAccounts
        : this.linkAccounts.filter((item) => !item.isForEachProperty)
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      linkAccounts: (state) => state.settings.linkAccounts,
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  mounted () {
    this.getLinkAccounts()
  },
  methods: {
    getLinkAccounts () {
      if (this.selectedTenant.length) {
        this.$store.dispatch(
          'settings/getLinkAccounts',
          this.isEditPage
            ? this.userRole === 'SysAdmin'
              ? this.tenantId
              : null
            : null
        )
      }
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      if (!this.isEditPage) {
        this.getLinkAccounts()
        this.widgetDetails.integrationWidgetSettings.linkAccountDetails = null
      }
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
