var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-5"},[_c('p',[_vm._v("Select an existing link account or add a new one")]),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
              'is-value-exist':
                _vm.widgetDetails.integrationWidgetSettings.linkAccountDetails !=
                null,
              'is-invalid':
                !_vm.widgetDetails.integrationWidgetSettings.linkAccountDetails &&
                _vm.formSubmitted,
            },attrs:{"id":"link-account"},on:{"input":function($event){_vm.widgetDetails.integrationWidgetSettings.linkAccountDetails =
                $event}},model:{value:(
              _vm.widgetDetails.integrationWidgetSettings.linkAccountDetails
            ),callback:function ($$v) {_vm.$set(_vm.widgetDetails.integrationWidgetSettings, "linkAccountDetails", $$v)},expression:"\n              widgetDetails.integrationWidgetSettings.linkAccountDetails\n            "}},_vm._l((_vm.getFilterdLinkAccounts),function(account,index){return _c('b-form-select-option',{key:index,attrs:{"value":{
                id: account.id,
                authenticationType: account.authenticationType,
              }}},[_vm._v(_vm._s(account.accountName))])}),1),_c('label',{attrs:{"for":"link-account"}},[_vm._v("Select link account")]),(
              !_vm.widgetDetails.integrationWidgetSettings.linkAccountDetails &&
              _vm.formSubmitted
            )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Link account required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('b-dropdown',{staticClass:"action-dropdown",attrs:{"variant":"primary","no-caret":"","left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Add new link account ")]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"aria-controls":"add-internal-link-account","aria-expanded":_vm.showInternalLinkAccountSideBar,"href":"javascript:void(0)"},on:{"click":function($event){_vm.showInternalLinkAccountSideBar = true}}},[_vm._v("Link account")]),_c('b-dropdown-item',{attrs:{"aria-controls":"add-external-link-account","aria-expanded":_vm.showExternalLinkAccountSideBar,"href":"javascript:void(0)"},on:{"click":function($event){_vm.showExternalLinkAccountSideBar = true}}},[_vm._v("External link account")])],1)],1)],1)],1),_c('AddEditInternalLinkAccountSideBar',{attrs:{"showInternalLinkAccountSideBar":_vm.showInternalLinkAccountSideBar},on:{"closeSideBar":function($event){_vm.showInternalLinkAccountSideBar = false}}}),_c('AddEditExternalLinkAccountSideBar',{attrs:{"showExternalLinkAccountSideBar":_vm.showExternalLinkAccountSideBar},on:{"closeSideBar":function($event){_vm.showExternalLinkAccountSideBar = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }